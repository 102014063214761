.header {
  height: 64px;
}

.logo {
  max-width: 168px;
}

.oldVersionLink {
  font-size: 14px;
}

.select {
  width: 160px;
}

.selectLabel {
  opacity: 0.54;
  padding: 0px !important;
}
