.header {
  height: 64px;
  margin-bottom: 16px;
}

.headerWithDates {
  height: 64px;
  margin-bottom: 24px;
  margin-top: 14px;
}

.select {
  width: 200px;
}

.selectLabel {
  opacity: 0.54;
  padding: 0px !important;
}

.titleText {
  font-size: 16px;
}

.titleReportName {
  font-size: 20px;
  font-weight: bold;
}
