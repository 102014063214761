.teamSizeTooltipHeader {
  font-weight: 700;
  margin-bottom: 8px;
}

.teamSizeTooltipDeveloper {
  font-weight: normal;
}

.teamSizeMore {
  font-weight: normal;
  margin-top: 8px;
}

.name {
  width: 250px !important;
}
.uniqueness {
  width: 130px !important;
}

.knowledge {
  width: 130px !important;
}

.repos {
  width: 130px !important;
}

.teamSize {
  width: 130px !important;
}
