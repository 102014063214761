.loader-base .ball-pulse > div {
  background-color: #999;

}

.loader-size-lg .ball-pulse > div {
  width: 40px;
  height: 40px;
}

.loader-size-md .ball-pulse > div {
  width: 15px;
  height: 15px;
}

.loader-size-sm .ball-pulse > div {
  width: 10px;
  height: 10px;
}

.loader-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
