.card {
  background: #fff;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.cardWithHover:hover {
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.title {
  font-size: 16px;
  font-weight: bold;
  height: 24px;
}

.body {
  margin-top: -24px;
  padding-top: 24px;
}
